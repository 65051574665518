import { graphql, useStaticQuery } from "gatsby";

export const useMDRTLogo = () => {
  const { dark, light } = useStaticQuery(graphql`{
  dark: file(relativePath: {eq: "mdrt-logo-black.png"}) {
    childImageSharp {
      small: gatsbyImageData(layout: FIXED, formats: [AUTO, WEBP])
      default: gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
      gatsbyImageData(layout: FULL_WIDTH, width: 320, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
  light: file(relativePath: {eq: "mdrt-logo-white.png"}) {
    childImageSharp {
      small: gatsbyImageData(layout: FIXED, height: 20, formats: [AUTO, WEBP])
      default: gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
}
`);
  return { dark, light };
};
